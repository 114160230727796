//@ts-nocheck
import React, { useEffect, useState } from "react";
import Layout from "../organisms/Layout";
import Wrapper from "../atoms/Wrapper";
import Container from "../atoms/Container";
import Text from "../atoms/Text";
import FAQ from "../atoms/FAQ";
import faqData from "../../src/assets/values/faqs.json";
import RevealAnimation from "../atoms/RevealAnimation";
import { PageSEO } from "../atoms/SEO";

type PROPS_STYLE = {
  location: any;
};
const TABS = ["General", "Seller", "Buyer"];
export default function Faqs(props: PROPS_STYLE) {
  const [tab, setTab] = useState("General");
  const [searchTerm, setSearchTerm] = useState("");
  const selectedCategories = faqData.categories.filter(
    (category) => category.tab === tab.toLowerCase()
  );

  const [selectedCategory, setSelectedCategory] = useState("");
  const [open, setOpen] = useState("");

  useEffect(() => {
    if (selectedCategories.length > 0) {
      if (
        selectedCategory === "" ||
        !selectedCategories.find((v) => selectedCategory === v.id)
      )
        setSelectedCategory(selectedCategories[0].id);
    }
  }, [tab]);
  useEffect(() => {
    setSearchTerm("");
  }, [selectedCategory, tab]);
  let selectedSet: { question: string; answer: string; category?: string }[] =
    faqData.questions[tab.toLowerCase()];
  const searchSet = [
    ...faqData.questions["general"],
    ...faqData.questions["seller"],
    ...faqData.questions["buyer"],
  ];
  if (searchTerm === "")
    selectedSet = selectedSet.filter(
      (set: any) =>
        (set.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
          set.answer.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (!set.category || selectedCategory === set.category)
    );
  else {
    selectedSet = searchSet.filter(
      (set: any) =>
        set.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        set.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  return (
    <Layout>
      <PageSEO
        path={props.location.pathname}
        title={"Frequently Asked Questions- SaaSPay"}
        description={"Frequently asked questions about SaaSPay."}
      />

      <Wrapper className={"bg-[#F8F9FA] px-4 xl:px-0"}>
        <Container className={"py-5 pt-[140px] md:pt-[180px] pb-[34px]"}>
          {/*<RevealAnimation animation={'fade'} className="   ">*/}
          {/*    <Text text={'FAQs'} className={'s1 '}/>*/}
          {/*</RevealAnimation>*/}
          <RevealAnimation animation={"fade"} className="mb-1 md:mb-[0px]">
            <Text
              component={"h2"}
              text={"Frequently Asked Questions"}
              className={"h1-l"}
            />
          </RevealAnimation>
          <RevealAnimation animation={"fade"} className="mb-3 md:mb-[43px]">
            <Text
              text={"Have questions? We're here to help."}
              className={"s1 !text-[#5B5B5B] opacity-80"}
            />
          </RevealAnimation>
          <RevealAnimation animation={"fade"} className=" relative">
            <input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={"search anything"}
              className={
                "rounded-[8px] px-7 h-[50px] md:h-[67px] w-full placeholder:b1 pr-[50px] md:pr-[85px] border-[1px] border-[#F4F4F4]"
              }
            />
            <svg
              className={
                "absolute right-[20px] md:right-[55px] top-1/2 -translate-y-1/2"
              }
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2662 13.0792L10.411 9.43093C11.3651 8.42851 11.9514 7.10683 11.9514 5.65474C11.9514 2.53674 9.27072 0 5.97572 0C2.68073 0 0 2.53674 0 5.65477C0 8.77279 2.68073 11.3095 5.97572 11.3095C7.51023 11.3095 8.9069 10.7546 9.96625 9.8518C9.96625 9.8518 13.7601 13.4419 13.8215 13.5C13.8829 13.5581 13.9634 13.5872 14.0439 13.5872C14.1244 13.5872 14.2048 13.5581 14.2663 13.5C14.3891 13.3837 14.3891 13.1954 14.2662 13.0792ZM5.97572 10.7143C3.0278 10.7143 0.629042 8.44463 0.629042 5.65477C0.629042 2.8649 3.0278 0.595228 5.97572 0.595228C8.92365 0.595228 11.3224 2.86487 11.3224 5.65477C11.3224 8.44466 8.92365 10.7143 5.97572 10.7143Z"
                fill="#2E2E2E"
              />
            </svg>
          </RevealAnimation>
          <RevealAnimation
            animation={"slide-right"}
            className={"flex mt-5   md:mt-14"}
          >
            {TABS.map((tabb) => (
              <div
                key={tabb}
                className={"mr-9 md:mr-[77px] cursor-pointer"}
                onClick={() => setTab(tabb)}
              >
                <Text
                  text={tabb}
                  className={tab === tabb ? "!text-p1" : ""}
                  textStyle={"inter-600-22-32"}
                />
                {tab === tabb && (
                  <div className={"w-[24px] h-[2px] bg-p1 md:mt-[7px]"} />
                )}
              </div>
            ))}
          </RevealAnimation>
        </Container>
      </Wrapper>
      <Wrapper className="px-4 xl:px-0">
        <Container className={"py-6 flex md:flex-nowrap flex-wrap"}>
          {selectedCategories.length > 0 && (
            <div
              className={
                "mb-5 md:mb-0 w-full md:w-[285px] flex-shrink-0 md:pl-[69px] md:pr-[60px] md:py-12 py-5 px-8 bg-[#F8F9FA] mr-[90px]"
              }
            >
              {selectedCategories.map((cat) => (
                <div
                  key={cat.id}
                  className={"mb-10 cursor-pointer relative flex "}
                  onClick={() => setSelectedCategory(cat.id)}
                >
                  {selectedCategory === cat.id && (
                    <span
                      className={
                        "absolute -left-4 top-3 w-2 h-2 rounded-full !bg-p1"
                      }
                    ></span>
                  )}
                  <Text
                    text={cat.name}
                    textStyle={"s2"}
                    className={selectedCategory === cat.id ? "!text-p1" : ""}
                  />
                </div>
              ))}
            </div>
          )}
          <div className={"w-full"}>
            {selectedSet.map(({ question, answer, category }, index) => (
              <FAQ
                key={question + index}
                question={question}
                answer={answer}
                open={open}
                setOpen={(openValue) => {
                  if (category) setSelectedCategory(category);
                  setOpen(openValue);
                }}
              />
            ))}
          </div>
        </Container>
      </Wrapper>
    </Layout>
  );
}
